import { Box, TextField } from '@mui/material';
import React, { useEffect } from 'react'

function Step0({ formData, setFormData, handleNext }: any) {


  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }


useEffect(() => {
  formData.name.trim().length > 2 ? handleNext(true) : handleNext(false)
}, [formData])

  return (
    <Box p={3} my={1} sx={{ bgcolor: "surface-container-lowest", borderRadius: "10px", display:'flex', flexDirection:'column', gap: 3 }}>
      <TextField
        name="name" 
        label="Analysis Name"
        fullWidth
        value={formData.name}
        placeholder="Enter a name"
        onChange={handleInputChange}
      />

      <TextField
        name="description"
        label="Description (optional)"
        fullWidth
        value={formData.description}
        placeholder="Enter a description"
        onChange={handleInputChange}
      />
    </Box>
  )
}

export default Step0;