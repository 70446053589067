import React, { useEffect, useState } from "react"
import { Alert, Box, Button, Snackbar, Typography } from "@mui/material"
import { SamplesProgressModal } from "../../SamplesProgModal"
import Popup from "../../../../components/popup"
import { SamplesReview } from "./SamplesReview"
import VariablesTable from "./VariablesTable"
import { useNavigate, useParams } from "react-router-dom"
import UpdatedSamplesTable from "./SamplesTable"
import { getAppConfig, useBff } from "../../../../utils/config"
import { useAuth0 } from "@auth0/auth0-react"
import { ButtonRow } from "../../../../components/ButtonRow"
import AddNewSample from "../../../ManageSamples/AddNewSample"

type FileData = {
    id: string;
    name: string;
    status?: string;
    lane_number?: number;
    read_number?: number;
};

type SampleData = {
    number_of_files?: any;
    name: string;
    other_categories: any;
    files?: Array<FileData>;
    id: string;
};

type SelectedSample = {
    value: string;
};

const Step4 = ({ experiment, setStep, setFormData, trimKeys }: any) => {
    const { id } = useParams()
    const { getAccessTokenSilently } = useAuth0();
    const { apiHost } = getAppConfig();
    const navigate = useNavigate()
    const [globalValues, setGlobalValues] = useState<any>([])
    const [displaySamples, setDisplaySamples] = useState([])
    const [addingSample, setAddingSample] = useState(false)
    const [deleteCallback, setDeleteCallback] = useState<any>(null);
    const [samplesToReview, setSamplesToReview] = useState<any>(null)
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedSamples, setSelectedSamples] = useState(new Set());
    const [errors, setErrors] = useState<any>({})
    const [samples, setSamples] = useState([])
    const selectedSamplesArray = Array.from(selectedSamples).map((value) => ({ value: value as string }));
    const [isDuplicationStarted, setDuplicationStarted] = useState<any>(false);


   
    // Parses the samples for categories and values for globalValues
    const parseCategories = (data: { other_categories: Record<string, any> }[]) => {
        const result: Record<string, Set<any>> = {};
        data.forEach(item => {
            const categories = item.other_categories;
            for (const key in categories) {
                if (!result[key]) {
                    result[key] = new Set();
                }
                result[key].add(categories[key]);
            }
        });
        const finalResult: Record<string, any[]> = {};
        for (const key in result) {
            finalResult[key] = Array.from(result[key]);
        }
        return finalResult;
    }

    // const validateFile = async (sample: any, file: any) => {
    //     const response = await fetch(
    //         `${apiHost}/experiment/${id}/sample/${sample.id}/file/${file.id}/validate-file-upload`,
    //         {
    //             method: "GET",
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 Authorization: `Bearer ${await getAccessTokenSilently()}`,
    //             }
    //         })
    //         const status = await response.json()
    //         return status
    // }

    // Checks the samples and files for errors
    const checkSamples = (samplesArr: any[], expectedFileCount: any) => {
        setErrors({})
        const errorsArr: Record<string, string[]> = {
            missingFiles: [],
            duplicateName: [],
            fileStatus: []
        };
        // samplesArr.forEach((sample:any) => {
        //     sample.files.map(async (file:any) => {
        //        await validateFile(sample, file)
        //     })
        // })
        const nameSet = new Set();
        samplesArr?.forEach((sample: any) => {
            const normalizedName = sample?.name?.trim().toLowerCase();
            if (nameSet?.has(normalizedName)) {
                errorsArr?.duplicateName?.push(sample.id);
            } else {
                nameSet.add(normalizedName);
            }
            if (expectedFileCount - sample?.files.length > 0) {
                errorsArr.missingFiles.push(sample.id);
            }
            if (sample.files.some((f:any) => f.status !== 'ready' && f.status !== 'unknown')) {
                errorsArr.fileStatus.push(sample.id)
            }

        });
        setErrors(errorsArr)
    }

    // Handlers for edits made with the Variables Table --->
    const handleVariableChange = async (newKey: string, oldKey: string) => {
        const editPromises = samples?.forEach((sample: any) => {
            if (sample.other_categories && oldKey in sample.other_categories) {
                const oldValue = sample.other_categories[oldKey];
                delete sample.other_categories[oldKey];
                sample.other_categories[newKey.trim()] = oldValue;
                return editSample(sample);
            } 
        });
        try {
            await editPromises
            setOpenSnackbar(true);
        } catch (error) {
            console.error('Error updating samples:', error);
        } finally {
            reFetchSamples();
        }
    };

    const handleValueChange = (newValue: string, key: string, index: number) => {
        const changedSamples: any[] = []
        const oldValue = globalValues[key][index]
        samples.forEach((sample: any) => {
            if (sample.other_categories[key] === oldValue) {
                sample.other_categories[key] = newValue
                changedSamples.push(sample)
            }
        })
        try {
            changedSamples.forEach((s: any) => {
                editSample(s)
            })
        } catch (err) {
            console.error(err)
        } finally {
            setSamplesToReview({ changedSamples, oldValue, newValue, key })
            reFetchSamples()
        }
    };

    // Handlers for search/filter ---->
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const filteredSamples = () => {
        if (!searchTerm) {
            return samples
        }
        return samples?.filter((sample: any) => {
            return sample?.name?.toLowerCase().includes(searchTerm.toLowerCase())
        });
    }

    const getSelectedSamples = (data: SampleData[], selectedSamples: SelectedSample[]): SampleData[] => {
        const selectedIds = selectedSamples.map((s: SelectedSample) => s.value);
        return data.filter((sample: SampleData) => selectedIds.includes(sample.id));
    }

    const filteredData = getSelectedSamples(displaySamples || [], selectedSamplesArray || [])

    const handleSelectRow = (id:any) => {
        const newSelectedSamples = new Set(selectedSamples);

        if (id && selectedSamples.has(id)) {
            newSelectedSamples.delete(id);
        } else {
            newSelectedSamples.add(id);
        }

        setSelectedSamples(newSelectedSamples);
    };

    const handleRemoveSample = (sampleId: string | undefined) => {
        if (typeof sampleId === 'string') {
            setSelectedSamples((prevSelectedSamples: Iterable<unknown> | ArrayLike<unknown>) => {
                const updatedSelectedSamples = new Set(Array.from(prevSelectedSamples));
                updatedSelectedSamples.delete(sampleId);

                return updatedSelectedSamples;
            });
        }
    };

    // Handlers for loading & updating the page ---->
    const reFetchSamples = async () => {
        const response = await fetch(
            `${apiHost}/experiment/${id}/sample`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${await getAccessTokenSilently()}`,
                }
            })
        const result = await response.json()
        checkSamples(result, experiment?.files_per_sample)
        setSamples(result)
        parseCategories(result)
        setDisplaySamples(result)  
    }


    const editSample = async (sample: any) => {
        try {
           trimKeys(sample.other_categories)
           console.log(sample.other_categories)
            Object.keys(sample).forEach(key => {
                if (key === 'flag' || key === 'archived') {
                    sample[key] = false
                }
                else if (sample[key] === null) {
                    key === 'number_of_files' ? sample[key] = 0 : sample[key] = '';
                }
            })
            const response = await fetch(
                `${apiHost}/experiment/${id}/sample/${sample.id}`,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${await getAccessTokenSilently()}`,
                    },
                    body: JSON.stringify(sample)
                })
            await response.json()
            await reFetchSamples()
        } catch (err) {
            console.error(err)
        }
    }


    useEffect(() => {
        reFetchSamples()
    }, [])

    useEffect(() => {
        reFetchSamples()
    }, [addingSample, openSnackbar])

    useEffect(() => {
        const values = parseCategories(samples)
        const sorted = Object.keys(values).sort()
        const sortedValues: any = {}
        sorted.forEach((k: any) => {
            sortedValues[k] = values[k]
        })
        setGlobalValues(sortedValues)
    }, [samples])

    useEffect(() => {
        const filtered = filteredSamples()
        setDisplaySamples(filtered)
    }, [searchTerm])

    const handleSave = () => {
        setFormData((state:any) => ({ ...state, step_three_details: globalValues }))
        setStep(4)
    }

    useEffect(() => {
        if (!filteredData.length) {
            setDuplicationStarted(false);
        }
    }, [filteredData]);

    return (
        <>
            <Box sx={{ mb: 4, py: 2, px: 3, background: "white", borderRadius: 4 }}>
                <VariablesTable
                    globalValues={globalValues}
                    data={displaySamples}
                    handleValueChange={handleValueChange}
                    handleVariableChange={handleVariableChange}
                />
            </Box>
            <Box sx={{ my: 3, p: 3, background: "white", borderRadius: 4 }}>
                <Typography variant="headline" size="small">Review your individual samples</Typography>
                <Typography mb={3} variant="body" size="medium">Make changes to the values assigned to your individual samples, and review your sample pairings to make sure they are accurate.</Typography>
                {addingSample &&
                    <Box py={1} sx={{ borderBottom: '1px solid', borderColor: 'outline-variant' }} >
                        <AddNewSample
                            data={displaySamples}
                            exp={experiment}
                            setAdding={setAddingSample}
                            sampleSource={displaySamples[0] || [{}]}
                            reFetchSamples={reFetchSamples}
                        />
                    </Box>}

                {isDuplicationStarted && filteredData.map((sample, index) => (
                    <AddNewSample
                        key={index}
                        data={displaySamples}
                        exp={experiment}
                        setAdding={setAddingSample}
                        isDuplicationStarted={isDuplicationStarted}
                        sampleSource={sample}
                        onRemoveSample={handleRemoveSample}
                        reFetchSamples={reFetchSamples}
                    />
                ))}

                {!addingSample && !isDuplicationStarted && <ButtonRow
                    onSearchChange={handleSearchChange}
                    addClick={setAddingSample}
                    onDelete={deleteCallback}
                    areSelectedSamples={!!selectedSamplesArray.length}
                    setDuplicationStarted={setDuplicationStarted}
                />}

                <UpdatedSamplesTable
                    experiment={experiment}
                    data={displaySamples}
                    errors={errors}
                    globalValues={globalValues}
                    fetchSamples={reFetchSamples}
                    setDeleteCallback={setDeleteCallback}
                    editSample={editSample}
                    setSuccess={setOpenSnackbar}
                    handleSelectRow={handleSelectRow}
                    selectedSamples={selectedSamples}
                    setSelectedSamples={setSelectedSamples}
                />
            </Box>
            <Button
                variant="contained"
                color="primary"
                style={{ margin: "20px 0" }}
                fullWidth
                onClick={() => handleSave()}
                disabled={Object.values(errors).some((arr:any) => arr.length > 0)}
            >
                Save and Continue
            </Button>
            <Button variant="outlined" color="primary" onClick={() => navigate(`/`)} fullWidth>
                Save Progress and Exit
            </Button>
            <Popup isOpen={samplesToReview} onClose={() => setSamplesToReview(null)} >
                <SamplesReview samplesToReview={samplesToReview} onClose={() => setSamplesToReview(null)} />
            </Popup>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={5000}
                onClose={() => setOpenSnackbar(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert severity="success">Changes were saved successfully!</Alert>
            </Snackbar>
        </>
    )
}

export default Step4
