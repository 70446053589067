import React from 'react';
import { Typography, Box, Paper, Button } from '@mui/material';
import { styled } from '@mui/system';
import { CheckCircle } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';

export const ContainerStyled = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '2%',
  backgroundColor: 'primary',
  color: 'onPrimary',
  alignItems:'flex-start'
});
 
export const PopupStyled = styled(Box)({
  padding: '2%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '16px',
});

export const StepStyled = styled(Box)({
  display: 'flex',
  alignItems: 'flex-start',
});

interface SamplesProgressModal {
  onClose?: () => void;
  fileUploadProgress: any;
}

interface GradientCircularProgressProps {
  gradientId: string;
  value?: number;
}

export const GradientCircularProgress: React.FC<GradientCircularProgressProps> = ({ gradientId, value }) => (
  <Box position="relative" display="inline-flex" marginLeft="30px">
    <CircularProgress
      variant="indeterminate"
      size={20}
      thickness={4}
      sx={{
        '& circle': {
          stroke: `url(#${gradientId})`,
        },
        '@keyframes rotate': {
          '0%': {
            transform: 'rotate(0deg)',
          },
          '100%': {
            transform: 'rotate(360deg)',
          },
        },
        animation: 'rotate 2s linear infinite',
        mr:2
      }}
    />
    <svg style={{ position: 'absolute' }} width="0" height="0">
      <defs>
        <linearGradient id={gradientId} x1="3" y1="12" x2="21" y2="12" gradientUnits="userSpaceOnUse">
          <stop stopColor="#7C57F4" />
          <stop offset="1" stopColor="#F7218C" />
        </linearGradient>
      </defs>
    </svg>
  </Box>
);

const remainingTime = (startTime: number, percentComplete: number) => {
  const currentTime = new Date().getTime();
  const elapsedTime = currentTime - startTime;
  const estimatedTotalTime = elapsedTime / (percentComplete / 100);
  const remainingTime = estimatedTotalTime - elapsedTime;
  return percentComplete > 5 ? new Date(currentTime + remainingTime).toLocaleTimeString() : 'Estimating Remaining Time...';
};

export const SamplesProgressModal: React.FC<SamplesProgressModal> = ({ onClose, fileUploadProgress }) => {
  const percentageComplete = Math.round((fileUploadProgress.doneCount / fileUploadProgress.totalFiles) * 100) + 3 || 1
  const donePercent = percentageComplete > 99 ? 99 : percentageComplete
  const estRemaining: string = remainingTime(fileUploadProgress.startTime, percentageComplete)
  const experimentName: string = fileUploadProgress.experimentName;
  const sampleName: string = fileUploadProgress.sampleName;
  const fileName: string = fileUploadProgress.fileName;
  
  return (
    <ContainerStyled>
      <PopupStyled>
        <Typography variant="headline" size="large"> 
          Processing Files
        </Typography>
        <Typography variant="body" size="large" sx={{ borderBottom: '1px solid', borderColor: 'onPrimary', pb: 2 }}>
          Please do not navigate away from this page or refresh your browser until processing is complete.
        </Typography>
        <StepStyled>
          <Typography>Create Samples:</Typography>
          {fileUploadProgress.createSamplesDone ? (
            <CheckCircle sx={{ fontSize: '20px' }} />
          ) : (
            <GradientCircularProgress gradientId="gradient-create-samples" />
          )}
        </StepStyled>
        {/*<StepStyled>*/}
        {/*  <Typography>Get Presigned URLs:</Typography>*/}
        {/*  {fileUploadProgress.getPresignedUrlsDone ? (*/}
        {/*    <CheckCircle sx={{ fontSize: '20px' }} />*/}
        {/*  ) : (*/}
        {/*    <GradientCircularProgress gradientId="gradient-get-presigned-urls" />*/}
        {/*  )}*/}
        {/*</StepStyled>*/}
        <StepStyled>
          <Typography>Upload Files:</Typography>
          {fileUploadProgress.uploadFilesDone ? (
              <CheckCircle sx={{ fontSize: '20px' }} />
          ) : (
              <Typography><GradientCircularProgress  gradientId="gradient-upload-files"  /> {`${donePercent}% complete`} </Typography>
          )}
        </StepStyled>
        {/*<StepStyled>*/}
        {/*  <Typography>Experiment:</Typography>*/}
        {/*  {fileUploadProgress.uploadFilesDone ? (*/}
        {/*      <CheckCircle sx={{ fontSize: '20px' }} />*/}
        {/*  ) : (*/}
        {/*      <Typography><GradientCircularProgress  gradientId="gradient-upload-files"  /> {`${experimentName}`} </Typography>*/}
        {/*  )}*/}
        {/*</StepStyled>*/}
        <StepStyled>
          <Typography>Sample:</Typography>
          {fileUploadProgress.uploadFilesDone ? (
              <CheckCircle sx={{ fontSize: '20px' }} />
          ) : (
              <Typography><GradientCircularProgress  gradientId="gradient-upload-files"  /> {`${sampleName}`} </Typography>
          )}
        </StepStyled>
        <StepStyled>
          <Typography>File:</Typography>
          {fileUploadProgress.uploadFilesDone ? (
            <CheckCircle sx={{ fontSize: '20px' }} />
          ) : (
           <Typography><GradientCircularProgress  gradientId="gradient-upload-files"  /> {`${fileName}`} </Typography>
          )}
        </StepStyled>
        <StepStyled>
          <Typography>Estimated Completion Time:</Typography>
          {fileUploadProgress.uploadFilesDone ? (
              <CheckCircle sx={{ fontSize: '20px' }} />
          ) : (
              <Typography><GradientCircularProgress  gradientId="gradient-upload-files"  /> {`${estRemaining}`} </Typography>
          )}
        </StepStyled>
      </PopupStyled>
    </ContainerStyled>
  );
};
