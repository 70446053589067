import React, { useEffect, useState } from "react"
import { Typography, Box } from "@mui/material"
import {
    AssignmentIndOutlined,
    AssignmentOutlined,
    CheckCircleOutlineRounded,
    CompareOutlined,
    IntegrationInstructionsOutlined,
    ListAltOutlined,
    SettingsOutlined
} from "@mui/icons-material"
import { styled } from "@mui/material/styles"
import { Button } from "../../components/buttons/Button"
import SelectVariables from "./steps/step2"
import SelectComparisons from "./steps/step3"
import EditSettings from "./steps/step4"
import Review from "./steps/step5"
import Popup from "../../components/popup"
import CreationFlowBar from "../../components/CreationFlowBar"
import { BeforeYouBegin } from "../../components/modals/analysis/BeforeYouBegin"
import { useAuth0 } from "@auth0/auth0-react"
import { getAppConfig, useBff, useTrovoConfig } from "../../utils/config"
import { useParams } from "react-router-dom"
import { AnalysisStarted } from "../../components/modals/analysis/AnaylysisStarted"
import { Loading } from "../../components/Loading"
import { Error } from "../../components/Error"
import ExpandableTable from "./steps/step1/ExpandableTable"
import BannerMessage from "../AccountDetails/BannerMessage"
import { demoSamples } from "../../utils/mock-data"
import Step0 from "./steps/Step0" 


const ExpContainer = styled(Box)({
    display: "flex",
    padding: "1.5% 8%",
    gap: 5
})
const FormContainer = styled(Box)({
    flex: 5,
    display: "flex",
    flexDirection: "column",
    padding: "1.5% 2%",
    gap: 10
})

function CreateAnalysis() {
    const [isBeforeYouBegin, setBeforeYouBeginOpen] = useState(false)
    const [error, setError] = useState<any>(null)
    const [isAnalysisStarted, setAnalysisStarted] = useState(false)
    const [step, setStep] = useState(0)
    const [ableToSave, setAbleToSave] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [samplesD, setSamples] = useState<any>([])
    const { getAccessTokenSilently } = useAuth0()
    const { apiHost } = getAppConfig()
    const { id, analysisId } = useParams()
    const [experiment] = useBff("GET", `experiment/${id}`)
    const [groupLevels, setGroupLevels] = useState([])
    const { user } = useTrovoConfig()
    const [contrastOptions, setContrastOptions] = useState([])
    const [formData, setFormData] = useState({
        name: "",
        description: '',
        samples: [],
        organism: "Homo_sapiens",
        status: "processing",
        archived: false,
        experiment_id: id,
        group_options: [],
        selected_group_options: {},
        selected_contrast_options: {},
        normalization_method: "Regulated log transformation (rld, rlog)",
        filter_source: "mean raw count",
        filter_threshold: 5,
        DE_padjust_method: "BH",
        lfc_shrinkage: "false",
        GO_padjust_method: "fdr",
        pvalue_option: "Adjusted P value",
        pvalue_threshold: 0.05,
        fold_change_threshold: 2,
        if_separate_regulation: "false",
        ontology_pvalue_cutoff: 0.05
    })
    console.log(formData)
    console.log(samplesD)
    interface RequestBody {
        sample_ids: string[]
        levels: {
            [key: string]: string[]
        }
    }
    function formatContrastRequestBody(formData: any, groupLevels: any): RequestBody {
        let requestBody: RequestBody = {
            sample_ids: formData.samples,
            levels: {}
        }

        function findOriginalKey(key: string, obj: any): string | null {
            const lowerCaseKey = key?.toLowerCase()
            return Object.keys(obj).find(currentKey => currentKey.toLowerCase() === lowerCaseKey) || null
        }

        formData?.group_options?.forEach((option: any) => {
            let normalizedKey = option.name.toLowerCase().replace(/ /g, "_")
            let originalKey = findOriginalKey(normalizedKey, groupLevels)
            if (!originalKey) {
                setError(`"${normalizedKey}" is not a valid option. Please choose a different option.`)
                return
            }
            let values: any[] = []
            values.push(option.value)
            let correspondingValues = groupLevels[originalKey] || []
            for (const value of correspondingValues) {
                if (!values.includes(value.toString())) {
                    values.push(value.toString())
                }
            }
            requestBody.levels[originalKey] = values
        })

        setFormData({ ...formData, selected_group_options: requestBody.levels })
        return requestBody
    }

    const getComparisons = async () => {
        const endpoint = `${apiHost}/resources/contrast-options`
        const method = "POST"
        const body = formatContrastRequestBody(formData, groupLevels)
        const response = await fetch(endpoint, {
            method: method,
            headers: {
                Authorization: `Bearer ${await getAccessTokenSilently()}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        })
        const responseData = await response.json()
        if (responseData.stderr) {
            setError(responseData.stderr)
            return
        }
        if (responseData?.Error) {
            setError("Error: " + Object.entries(responseData.Error).join(", "))
            return
        }
        if (JSON.parse(responseData)?.options) {
            setContrastOptions(JSON.parse(responseData).options)
            setError(null)
            setStep(step + 1)
        }
    }

    const getGroupOptions = async () => {
        const endpoint = `${apiHost}/resources/group-options`
        const method = "POST"
        const body = JSON.stringify(formData.samples)
        const response = await fetch(endpoint, {
            method: method,
            headers: {
                Authorization: `Bearer ${await getAccessTokenSilently()}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: body
        })
        const responseData = await response.json()
        console.log(responseData)
        if (responseData.stderr) {
            setError(responseData.stderr)
            return
        }

        if (response.status == 200) {
            setGroupLevels(responseData?.levels)
            setStep(2)
            setError(null)
            return
        }
        if (response.status == 400) {
            return setError(responseData?.Error || responseData)
        }
        if (responseData.Error) {
            setError(responseData.Error)
        }
    }

    const getSamplesForExperiment = async () => {
        setIsLoading(true)
        try {
            const endpoint = `${apiHost}/experiment/${id}/sample`
            const method = "GET"
            const response = await fetch(endpoint, {
                method: method,
                headers: {
                    Authorization: `Bearer ${await getAccessTokenSilently()}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            const responseData = await response.json()
            if (response.ok) {
                setSamples(id === "4015c7cc-1982-4bd4-ae1c-140a7aa6fba9" ? demoSamples : responseData)
            } else {
                console.error(responseData.message || "An error occurred.")
            }
        } catch (err: any) {
            console.error(err.message || "An error occurred.")
        } finally {
            setIsLoading(false)
        }
    }
    

    const createAnalysis = async () => {
        const { name, description, organism, selected_group_options, status, archived, experiment_id } = formData
        const endpoint = `${apiHost}/experiment/${id}/analysis/create`
        const method = "POST"
        const response = await fetch(endpoint, {
            method: method,
            headers: {
                Authorization: `Bearer ${await getAccessTokenSilently()}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                analysisMetadata: {
                    name,
                    description: description || ' ',
                    organism,
                    group_options: selected_group_options,
                    status,
                    archived,
                    experiment_id
                },
                sample_ids: formData.samples
            })
        })
        const responseData = await response.json()
        if (response.ok) {
            console.log(responseData)
            const a_id = responseData.id
            const endpoint = `${apiHost}/experiment/${id}/analysis/${a_id}/start`
            const method = "POST"
            const startResponse = await fetch(endpoint, {
                method: method,
                headers: {
                    Authorization: `Bearer ${await getAccessTokenSilently()}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    selected_group_options: formData.selected_group_options,
                    selected_contrast_options: formData.selected_contrast_options
                })
            })
            const startResponseData = await startResponse.json()
            console.log(startResponseData)
            if (startResponse.ok) {
                setAnalysisStarted(true)
            }
        } else {
            console.error(responseData.message || "An error occurred.")
        }
    }

    const handleSave = async () => {
        step === 0 && setStep(step + 1)
        step === 1 && (await getGroupOptions())
        step === 2 && formData.group_options.length && (await getComparisons())
        step === 3 && setStep(step + 1)
        step === 4 && setStep(step + 1)
        step === 5 && createAnalysis() 
    }

    const steps = [
        {
            name: "Analysis details",
            caption: "Name your analysis and provide a description",
            description:
                "Name your analysis and optionally provide a description.",
            component: (
                <Step0
                    formData={formData}
                    setFormData={setFormData}
                    handleNext={setAbleToSave}
                    data={samplesD}
                    error={error}
                    setError={setError}
                />
            ),
            icon: <AssignmentOutlined sx={{ fill: step === 0 ? "url(#linearColors)" : "" }} />
        },
        {
            name: "Select samples",
            caption: "Indicate which samples you want to include in your analysis.",
            description:
                "Indicate which samples should be included for analysis. You can edit the sample or its flagged status in Manage Samples.",
            component: (
                <ExpandableTable
                    formData={formData}
                    setFormData={setFormData}
                    handleNext={setAbleToSave}
                    data={samplesD}
                    error={error}
                    setError={setError}
                />
            ),
            icon: <IntegrationInstructionsOutlined sx={{ fill: step === 1 ? "url(#linearColors)" : "" }} />
        },
        {
            name: "Select variables",
            caption: "Designate variables to compare in this analysis.",
            description:
                "Select up to two variables for comparison in this analysis and indicate each variable’s control value. ",
            component: (
                <SelectVariables
                    formData={formData}
                    setFormData={setFormData}
                    handleNext={setAbleToSave}
                    groupLevels={groupLevels}
                    error={error}
                    setError={setError}
                />
            ),
            icon: <ListAltOutlined sx={{ fill: step === 2 ? "url(#linearColors)" : "" }} />
        },
        {
            name: "Select comparisons",
            caption: "Choose comparison values to be viewed in this analysis.",
            description:
                "Choose the comparisons you want to have available for visualization. These comparisons determine how you will be able to interact with the visualization of this data set. You can select as many comparisons as you wish. ",
            component: (
                <SelectComparisons
                    formData={formData}
                    setFormData={setFormData}
                    handleNext={setStep}
                    contrastOptions={contrastOptions}
                />
            ),
            icon: <CompareOutlined sx={{ fill: step === 3 ? "url(#linearColors)" : "" }} />
        },
        {
            name: "Edit analysis parameter settings",
            caption: "Advanced configuration parameters for analysis.",
            description: "View and manage options for data analysis. ",
            component: <EditSettings formData={formData} setFormData={setFormData} handleNext={setStep} />,
            icon: <SettingsOutlined sx={{ fill: step === 4 ? "url(#linearColors)" : "" }} />
        },
        {
            name: "Review analysis",
            caption: "Check your setup before submitting for analysis.",
            description:
                "Confirm your analysis setup. Once an analysis has been started, its settings cannot be changed. If you want to run variations of this analysis, you will need to go through the setup process again.",
            component: (
                <Review
                    formData={formData}
                    setFormData={setFormData} 
                    handleNext={setStep}
                    samples={samplesD}
                    contrastOptions={contrastOptions}
                />
            ),
            icon: <CheckCircleOutlineRounded sx={{ fill: step === 5 ? "url(#linearColors)" : "" }} />
        }
    ]

    useEffect(() => {
        getSamplesForExperiment()
        setFormData({ ...formData, organism: experiment?.organism.split(" ").join("_") || samplesD?.[0]?.organism })
    }, [])
    useEffect(() => {
        setFormData({ ...formData, organism: experiment?.organism.split(" ").join("_") || samplesD?.[0]?.organism })
    }, [samplesD])
    return (
        <ExpContainer>
            <CreationFlowBar title="SETUP NEW ANALYSIS" steps={steps} step={step} setStep={setStep} />
            <FormContainer>
                <Box sx={{ marginBottom: "40px" }}>
                    <BannerMessage
                        show={user.cb_item_price_id?.includes("demo")}
                        setHide={() => null}
                        showClose={false}
                        title="Upgrade to a paid plan to unlock all features."
                        message="You are currently using a demo account."
                    />
                </Box>
                <Typography variant="headline" size="large">
                    {steps[step].name}
                </Typography>
                <Typography variant="body" size="medium" mb={2}>
                    {steps[step].description}
                </Typography>
                {isLoading ? <Loading /> : steps[step].component}
                {error && <Error message={error} />}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                    disabled={!ableToSave || user.cb_item_price_id?.includes("demo")}>
                    {step !== 5 ? "Continue" : "Run analysis"}
                </Button>
                <Popup
                    isOpen={isBeforeYouBegin}
                    onClose={() => setBeforeYouBeginOpen(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description">
                    <BeforeYouBegin onClose={() => setBeforeYouBeginOpen(false)} />
                </Popup>
                <Popup
                    isOpen={isAnalysisStarted}
                    onClose={() => setAnalysisStarted(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description">
                    <AnalysisStarted experimentId={id} onClose={() => setAnalysisStarted(false)} />
                </Popup>
            </FormContainer>
        </ExpContainer>
    )
}

export default CreateAnalysis
