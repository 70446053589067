import React, { useState } from 'react';
import { Autocomplete, TextField, Box, Button, FormControl, InputLabel, Select, MenuItem, FilledTextFieldProps, OutlinedTextFieldProps, StandardTextFieldProps, TextFieldVariants, styled, Paper } from '@mui/material';
import moment from 'moment'; 



function Filters({ initialList, setFilteredList, filteredList, setShowFilters }:any) {
  const data = initialList;
  const [analysisID, setAnalysisID] = useState(null);
  const [title, setTitle] = useState(null);
  const [status, setStatus] = useState('');
  const [selectedDateRange, setSelectedDateRange] = useState('');

  const getStatusOptions = () => ['completed', 'failed', 'processing'];

  const dateRanges = [
    { label: 'This week', start: moment().startOf('week'), end: moment().endOf('week') },
    { label: 'Last week', start: moment().subtract(1, 'weeks').startOf('week'), end: moment().subtract(1, 'weeks').endOf('week') },
    { label: 'Last two weeks', start: moment().subtract(2, 'weeks').startOf('week'), end: moment().endOf('week') },
    { label: 'Last month', start: moment().subtract(1, 'month').startOf('month'), end: moment().subtract(1, 'month').endOf('month') },
    { label: '90+ days ago', start: moment().subtract(90, 'days'), end: null }
  ];

  const filterData = () => {
    const range = dateRanges.find(range => range.label === selectedDateRange);
    const filtered = data.filter((item: any) => {
      const timeStarted = moment(item.time_started);
      const withinDateRange = range?.end ? timeStarted.isBetween(range.start, range.end, undefined, '[]') : timeStarted.isBefore(range?.start);
      const idMatches = analysisID ? item?.analysis_num === analysisID : true;
      const statusMatches = status ? item?.status === status : true;
      const titleMatches = title ? item?.title === title : true;

      return withinDateRange && idMatches && statusMatches && titleMatches;
    });
    setFilteredList(filtered)
  };

  const resetFilters = () => {
    setAnalysisID(null);
    setTitle(null);
    setStatus('');
    setSelectedDateRange('');
    setFilteredList(null)
  };

  return (
    <Paper elevation={3} sx={{p:2, display:'flex', flexDirection:'column', gap:1, width:350}}>
      <Autocomplete
        options={data?.map((option: { analysis_num: any; }) => option.analysis_num)}
        value={analysisID}
        onChange={(event, newValue) => {
          setAnalysisID(newValue);
        }}
        renderInput={(params) => <TextField {...params} label="Analysis ID" />}
      />
      <Autocomplete
        options={data?.map((option: { title: any; }) => option.title)}
        value={title}
        onChange={(event, newValue) => {
          setTitle(newValue);
        }}
        renderInput={(params) => <TextField {...params} label="Analysis Name" />}
      />
      <FormControl fullWidth>
        <InputLabel id="date-range-label">Date Range</InputLabel>
        <Select
          labelId="date-range-label"
          id="date-range-select"
          value={selectedDateRange}
          label="Date Range"
          onChange={(e) => setSelectedDateRange(e.target.value)}
        >
          {dateRanges.map((option, index) => (
            <MenuItem key={index} value={option.label}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="status-select-label">Status</InputLabel>
        <Select
          labelId="status-select-label"
          id="status-select"
          value={status}
          label="Status"
          onChange={(e) => setStatus(e.target.value)}
        >
          {getStatusOptions().map((option, index) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Button variant="outlined" onClick={resetFilters}>
          Reset
        </Button>
        <Button variant="contained" onClick={filterData}>
          View results
        </Button>
      </Box>
    </Paper>
  );
};

export default Filters;