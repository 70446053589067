import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Box, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import CreationFlowBar from "../../components/CreationFlowBar";
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import {
    BackupOutlined,
    CheckCircleOutlineRounded,
    IntegrationInstructionsOutlined,
    PendingActions,
    PollOutlined
} from "@mui/icons-material";
import Step3 from "./steps/step3/Step3Index";
import Step4 from "./steps/step4/IndexStep4";
import Step5 from "./steps/Step5";
import {useAuth0} from "@auth0/auth0-react";
import {getAppConfig, useTrovoConfig} from "../../utils/config";
import BannerMessage from "../AccountDetails/BannerMessage";
import {Loading} from "../../components/Loading";
import Popup from "../../components/popup";
import {SamplesProgressModal} from "./SamplesProgModal";

const ExpContainer = styled(Box)({
    display: "flex",
    margin: "0 auto",
    width: "fit-content",
    paddingTop: "48px",
    gap: "20px"
});
const FormContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    width: "990px",
    gap: 10,
    marginBottom: '55px'
});
const formDataInitialState = {
    experiment_details: {
        name: "",
        description: "",
        organism: "",
        sequencing_type: ""
    },
    sequencing_details: {
        analyzed_molecule: "",
        rna_selection_method: "",
        sequencing_adapter: "",
        sequencing_platform: "",
        platform_model: "",
        sequencing_read_type: "",
        sequencing_sense: "",
        files_per_sample: ""
    },
    step_three_details: {},
    step_four_details: {},
};

function CreateExperiment() {
    const { getAccessTokenSilently } = useAuth0();
    const { apiHost } = getAppConfig();
    const { id } = useParams();
    const navigate = useNavigate();
    const { user } = useTrovoConfig();
    const [samples, setSamples] = useState([]);
    const [step, setStep] = useState(0);
    const [experiment, setExperiment] = useState(null);
    const [fileUploadProgress, setFileUploadProgress] = useState({
        processing: false,
        createSamplesDone: false,
        getPresignedUrlsDone: false,
        uploadFilesDone: false,
        doneCount: 0,
        totalFiles: 0,
        startTime: (new Date()).getTime(),
        experimentName: '',
        sampleName: '',
        fileName: ''
    });
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState(formDataInitialState);

    // const validateFile = async (sample:any, file:any) => {
    //     const response = await fetch(
    //         `${apiHost}/experiment/${id}/sample/${sample.id}/file/${file.id}/validate-file-upload`,
    //         {
    //             method: "GET",
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 Authorization: `Bearer ${await getAccessTokenSilently()}`,
    //             }
    //         });
    //     return await response.json();
    // };

    const fetchSamples = async () => {
        if (!id) return;
        try {
            const response = await fetch(`${apiHost}/experiment/${id}/sample`, {
                headers: {
                    Authorization: `Bearer ${await getAccessTokenSilently()}`
                }
            });
            const data = await response.json();
            // await Promise.all(data.map(async (s:any) => {
            //     await Promise.all(s.files.map((f:any) => validateFile(s, f)));
            // }));
            // console.log("All files validated.");
            setSamples(data.sort((a:any, b:any) => new Date(a.created_time).getTime() - new Date(b.created_time).getTime()));
        } catch (err:any) {
            console.error(err.message || "An error occurred.");
        }
    };

    const fetchData = async () => {
        if (!id) return;
        setLoading(true);
        try {
            const response = await fetch(`${apiHost}/experiment/${id}`, {
                headers: {
                    Authorization: `Bearer ${await getAccessTokenSilently()}`
                }
            });
            const data = await response.json();
            console.log(data)
            determineStepBasedOnData(data);
            setExperiment(data);
            setFileUploadProgress(state => ({ ...state, experimentName: data ? (data['name'] ? data['name'] : '') : '' }));
            const {
                name,
                description,
                organism,
                sequencing_type,
                analyzed_molecule,
                rna_selection_method,
                sequencing_adapter,
                sequencing_platform,
                platform_model,
                sequencing_read_type,
                sequencing_sense,
                files_per_sample,
                sample_count
            } = data;

            setFormData({
                experiment_details: { name, description, organism, sequencing_type },
                sequencing_details: {
                    analyzed_molecule,
                    rna_selection_method,
                    sequencing_adapter,
                    sequencing_platform,
                    platform_model,
                    sequencing_read_type,
                    sequencing_sense,
                    files_per_sample
                },
                step_three_details: {},
                step_four_details: {}
            });
        } catch (err:any) {
            console.error(err.message || "An error occurred.");
        } finally {
            setLoading(false);
        }
    };

    const determineStepBasedOnData = (data:any) => {
        if (!data.name || !data.organism || !data.sequencing_type) {
            setStep(0);
        }
        if (!data.analyzed_molecule || !data.sequencing_read_type || !data.files_per_sample) {
            setStep(1);
        }
        if (data.sample_count > 0) {
            setStep(3);
        } else {
            setStep(2);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        fetchSamples();
    }, [step]);

    const handleSave = async (whatToSave:any) => {
        if (whatToSave) {
            try {
                const body = id
                    ? { ...whatToSave }
                    : { ...whatToSave, archived: false, pending: true, group_options: {} };
                const endpoint = id ? `${apiHost}/experiment/${id}` : `${apiHost}/experiment/create`;
                const method = id ? "PUT" : "POST";
                const response = await fetch(endpoint, {
                    method: method,
                    headers: {
                        Authorization: `Bearer ${await getAccessTokenSilently()}`,
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(body)
                });
                const responseData = await response.json();
                if (response.ok) {
                    if (!id) {
                        navigate(`/experiment/draft/${responseData.id}`);
                    }
                } else {
                    console.error(responseData.message || "An error occurred.");
                }
            } catch (err:any) {
                console.error(err.message || "An error occurred.");
            }
        }
        step === 4 ? navigate("/") : setStep(step + 1);
    };

    const createSamples = async (obj:any) => {
        let totalCount = 0;
        setFileUploadProgress(state => ({ ...state, processing: true }));
        const samplesPromises = obj.values_by_sample.map((sample:any) => {
            const filenameArray = obj.files_list[sample.SampleName] || [];
            const fileObjects = filenameArray.map((filename:any) =>
                obj.fastq_files.find((file:any) => file.name === filename)
            );
            return createSample(sample, fileObjects);
        });
        const responses = await Promise.all(samplesPromises);
        const data: Awaited<any>[] = await Promise.all(responses.map(response => response.json()));
        setFileUploadProgress(state => ({ ...state, createSamplesDone: true }));

        if (!id) {
            throw new Error("Experiment ID not found.");
        }
        let fileCount: number = 0;
        data.forEach(sample => {
            fileCount += sample.files.length ? sample.files.length : 0
        })
        setFileUploadProgress(prevState => ({ ...prevState, totalFiles: fileCount }))
        for (const sample of data) {
            const sampleId = sample.id;
            const files = sample.files;

            setFileUploadProgress(state => ({ ...state, processing: true, sampleName: sample ? (sample['name'] ? sample['name'] : '') : '' }));

            for (const file of files) {
                const fileId = file.id;
                setFileUploadProgress(state => ({ ...state, processing: true, fileName: sample ? (file['name'] ? file['name'] : '') : '' }));

                totalCount++;
                try {
                    // const response = await getPresignedUrl(sampleId, fileId, file.name);
                    const fileToUpload = obj.fastq_files.find((f: { name: any; }) => f.name === file.name);
                    if (fileToUpload) {
                        await uploadFileMultiPart(fileToUpload, id, sampleId, fileId, 3);
                        setFileUploadProgress(prevState => ({ ...prevState, doneCount: prevState.doneCount + 1 }))
                    }
                } catch (error) {
                    console.error(`Error processing file ${file.name}:`, error);
                }
            }
        }

        await fetchSamples();
        setFileUploadProgress(state => ({ ...state, processing: false, uploadFilesDone: true }));
        setStep(3);
    };

    function trimKeysAndValuesInObject(obj: Record<string, any>): Record<string, any> {
        const trimmedObject: Record<string, any> = {}
        Object.keys(obj).forEach(key => {
          const trimmedKey = key.trim();
          const value = obj[key];
          const trimmedValue = typeof value === 'string' ? value.trim() : value;
          trimmedObject[trimmedKey] = trimmedValue;
        })
        return trimmedObject;
      }

    const createSample = async (sample:any, fileObjects:any) => {
        const { SampleName: sampleName, Filename: filename, ...other_categories } = sample;
        const otherCategoriesTrimmed = trimKeysAndValuesInObject(other_categories);
        const fileObjectsToInsert = fileObjects.map((obj:any) => {
            const filename = obj.name;
            const filenameSplit = filename.split("_");
            const laneNumber = filenameSplit.length >= 5 ? filenameSplit[filenameSplit.length - 3][3] : "1";
            const readNumber =
                filenameSplit.length >= 5
                    ? filenameSplit[filenameSplit.length - 2][1]
                    : filenameSplit.length === 2
                        ? filenameSplit[1][0]
                        : "1";
            return {
                name: filename,
                original_size: obj.size,
                strandedness_seq_sense: formData.sequencing_details.sequencing_sense,
                strandedness_seq_end:
                    formData.sequencing_details.sequencing_read_type &&
                    formData.sequencing_details.sequencing_read_type.toLowerCase(),
                interleaved: false,
                status: "not_ready",
                lane_number: laneNumber,
                read_number: readNumber
            };
        });

        const sampleRecord = {
            name: sampleName,
            organism: formData.experiment_details.organism,
            analyzed_molecule: formData.sequencing_details.analyzed_molecule,
            sequencing_instrument_platform: formData.sequencing_details.sequencing_platform,
            sequencing_instrument_model: formData.sequencing_details.platform_model,
            experiment_id: id,
            other_categories: otherCategoriesTrimmed,
            files: fileObjectsToInsert
        };
        return fetch(`${apiHost}/experiment/${id}/sample/create`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${await getAccessTokenSilently()}`
            },
            body: JSON.stringify(sampleRecord)
        });
    };

    const getPresignedUrl = async (sampleId:any, fileId:any, filename:any) => {
        return fetch(`${apiHost}/experiment/${id}/sample/${sampleId}/file/${fileId}/get-presigned-urls`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${await getAccessTokenSilently()}}`
            }
        })
            .then(response => response.json());
    };

    // async function uploadFileMultiPart(file:any, presignedUrls:any) { //, uploadId:any) {
    //     const chunkSize = 5 * 1024 * 1024; // 5MB
    //     const chunks = Math.ceil(file.size / chunkSize);
    //     // const uploadPromises = [];
    //
    //     for (let i = 0; i < chunks; i++) {
    //         const start = i * chunkSize;
    //         const end = Math.min(start + chunkSize, file.size);
    //         const blob = file.slice(start, end);
    //         const presignedUrl = presignedUrls[i];
    //
    //         try {
    //             await fetch(presignedUrl, {
    //                 method: 'PUT',
    //                 headers: {
    //                     'Content-Type': 'application/octet-stream',
    //                 },
    //                 body: blob,
    //             });
    //         } catch (error) {
    //             console.error('Error uploading file parts:', error);
    //         }
    //
    //         // uploadPromises.push(
    //         //     fetch(presignedUrl, {
    //         //         method: 'PUT',
    //         //         headers: {
    //         //             'Content-Type': 'application/octet-stream',
    //         //         },
    //         //         body: blob,
    //         //     }).then(response => {
    //         //         if (!response.ok) {
    //         //             throw new Error(`Upload failed for chunk ${i}`);
    //         //         }
    //         //         return response;
    //         //     })
    //         // );
    //     }
    //     // try {
    //     //     uploadPromises.forEach((promise) => {
    //     //         await promise;
    //     //     });
    //     //     console.log('All parts uploaded successfully');
    //     // } catch (error) {
    //     //     console.error('Error uploading file parts:', error);
    //     // }
    // }

    // async function uploadFileMultiPart(file:any, experimentId: string, sampleId: string, fileId: string) {
    //     const chunkSize = 5 * 1024 * 1024; // 5MB
    //     const chunks = Math.ceil(file.size / chunkSize);
    //
    //     for (let i = 0; i < chunks; i++) {
    //         const start = i * chunkSize;
    //         const end = Math.min(start + chunkSize, file.size);
    //         const blob = file.slice(start, end);
    //
    //         try {
    //             const uploadPartResponse: Response = await fetch(`${apiHost}/experiment/${experimentId}/sample/${sampleId}/file/${fileId}/upload-file-part/${i+1}/${chunks}/${chunkSize}`, {
    //                 method: 'PUT',
    //                 headers: {
    //                     Authorization: `Bearer ${await getAccessTokenSilently()}`,
    //                     'Content-Type': 'application/octet-stream',
    //                 },
    //                 body: blob
    //             });
    //             if (!uploadPartResponse.ok) {
    //                 throw new Error(`Upload failed for chunk ${i}`);
    //             } else {
    //                 console.log(`Upload successful for chunk ${i} of ${chunks} for file ${file.name}`);
    //             }
    //         } catch (error) {
    //             console.error('Error uploading file parts:', error);
    //         }
    //     }
    // }

    async function uploadFileMultiPart(file: any, experimentId: string, sampleId: string, fileId: string, n: number) {
        const chunkSize = 5 * 1024 * 1024; // 5MB
        const chunks = Math.ceil(file.size / chunkSize);

        // Function to upload a single chunk
        const uploadChunk = async (i: number) => {
            const start = i * chunkSize;
            const end = Math.min(start + chunkSize, file.size);
            const blob = file.slice(start, end);

            let tries = 1;

            while (tries <= 5) {
                try {
                    const uploadPartResponse: Response = await fetch(`${apiHost}/experiment/${experimentId}/sample/${sampleId}/file/${fileId}/upload-file-part/${i + 1}/${chunks}/${chunkSize}`, {
                        method: 'PUT',
                        headers: {
                            Authorization: `Bearer ${await getAccessTokenSilently()}`,
                            'Content-Type': 'application/octet-stream',
                        },
                        body: blob
                    });
                    if (!uploadPartResponse.ok) {
                        throw new Error(`Upload failed for chunk ${i}`);
                    } else {
                        console.log(`Upload successful for chunk ${i + 1} of ${chunks} for file ${file.name}`);
                        break
                    }
                } catch (error) {
                    console.error('Error uploading file parts:', error);
                    console.log(`Retrying ${tries} / 5`)
                    tries++
                }
            }
        };

        // Function to upload a batch of chunks
        const uploadBatch = async (startIndex: number, endIndex: number) => {
            const uploadPromises = [];
            for (let i = startIndex; i < endIndex; i++) {
                uploadPromises.push(uploadChunk(i));
            }
            await Promise.all(uploadPromises);
        };

        // Process chunks in batches of 'n'
        for (let i = 0; i < chunks; i += n) {
            const endIndex = Math.min(i + n, chunks);
            try {
                await uploadBatch(i, endIndex);
            } catch (error) {
                console.error(`Error uploading batch starting at chunk ${i}:`, error);
                break; // Exit the loop on error
            }
        }
    }

    const steps = [
        {
            name: "Enter Experiment Details",
            caption: "Record your experiment name, description, and other identifying information.",
            description:
                "Record the identifying details of your experiment. You can use this information to create a unique identifier for your experiment, such as “Experiment 1: Transcriptome of analysis of human heart failure.” We recommend using clear, descriptive naming conventions to help you organize and differentiate between your experiments. ",
            component: (
                <Step1
                    formData={formData}
                    setExperimentDetails={(obj) => {
                        setFormData(state => ({ ...state, experiment_details: obj }));
                        handleSave(obj);
                    }}
                />
            ),
            icon: <PendingActions sx={{ fill: step === 0 ? "url(#linearColors)" : "" }} />
        },
        {
            name: "Enter Sequencing Details",
            caption: "Enter required information about your data files.",
            description:
                "Select the configuration that corresponds with your data files as provided by the organization that generated them. ",
            component: (
                <Step2
                    formData={formData}
                    setSequencingDetails={(obj) => {
                        setFormData(state => ({ ...state, sequencing_details: obj }));
                        handleSave(obj);
                    }}
                />
            ),
            icon: <PollOutlined sx={{ fill: step === 1 ? "url(#linearColors)" : "" }} />
        },
        {
            name: "Build Your Experiment",
            caption: "Format and upload your sample information.",
            component: (
                <Step3
                    experiment={experiment}
                    createSamples={createSamples}
                    samples={samples}
                    setStep={setStep}
                />
            ),
            icon: <BackupOutlined sx={{ fill: step === 2 ? "url(#linearColors)" : "" }} />
        },
        {
            name: "Edit Samples",
            caption: "Review and edit the labels of sample variables and values.",
            description:
                "Make global changes to the variable and value labels you are using throughout this experiment. Changes to these labels will appear in any sample to which they are assigned.",
            component: (
                <Step4
                    experiment={experiment}
                    setFormData={setFormData}
                    setStep={setStep}
                    trimKeys={trimKeysAndValuesInObject}
                />
            ),
            icon: <IntegrationInstructionsOutlined sx={{ fill: step === 3 ? "url(#linearColors)" : "" }} />
        },
        {
            name: "Review & Create",
            caption: "Check your work before finalizing this experiment.",
            component: (
                <Step5
                    experiment={experiment}
                    formData={formData}
                    samples={samples}
                    setStep={setStep}
                    submitExperiment={() => handleSave({ pending: false })}
                />
            ),
            icon: <CheckCircleOutlineRounded sx={{ fill: step === 4 ? "url(#linearColors)" : "" }} />
        }
    ];

    return (
        <Box>
            {loading || fileUploadProgress.processing ? (loading ? (
                <Loading />
            ) : (
                <Popup isOpen={fileUploadProgress ? true : false}>
                    <SamplesProgressModal fileUploadProgress={fileUploadProgress} />
                </Popup>
            )
            ) : (
                <ExpContainer>
                    <CreationFlowBar title="CREATE NEW EXPERIMENT" steps={steps} step={step} setStep={setStep} />
                    <FormContainer>
                        <Box sx={{ marginBottom: "40px" }}>
                            <BannerMessage
                                show={user.cb_item_price_id?.includes("demo")}
                                setHide={() => null}
                                showClose={false}
                                title="Upgrade to a paid plan to unlock all features."
                                message="You are currently using a demo account."
                            />
                        </Box>
                        <Typography variant="headline" size="large">
                            {steps[step].name}
                        </Typography>
                        <Typography variant="body" size="medium" mb={2}>
                            {steps[step].description}
                        </Typography>
                        <div
                            style={{
                                margin: "2px 0",
                                padding: step !== 3 ? 20 : 0,
                                background: step !== 3 ? "white" : "",
                                borderRadius: 10
                            }}>
                            {steps[step].component}
                        </div>
                    </FormContainer>
                </ExpContainer>
            )}
        </Box>
    );
}

export default CreateExperiment;
