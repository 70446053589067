import React, { ChangeEvent, useState } from "react"
import {
    Box,
    IconButton,
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material"
import { Check, CheckBox } from "@mui/icons-material"

const VariablesTable = ({ globalValues, data, handleValueChange, handleVariableChange }: any) => {
    const [editState, setEditState] = useState<{ [key: string]: string }>({});

    const handleChange = (identifier: string, newValue: string) => {
        setEditState({ [identifier]: newValue });
    };


    const handleSaveKeyChanges = () => {
        const oldKey = Object.keys(editState)[0]
        const newKey = editState[oldKey]
        handleVariableChange(newKey, oldKey)
        setEditState({})
    }

    const handleSaveValueChanges = () => {
        const value = Object.values(editState)[0]
        const keySplit = Object.keys(editState)[0].split('-')
        const key = keySplit[0]
        const index = keySplit[1]
        handleValueChange(value, key, index)
        setEditState({})
    }

    return (
        <Box>
            <Typography variant="headline" size="small">Review your variables and values</Typography>
            <Typography variant="body" size="medium">Make global changes to the variables and values you are using throughout this experiment. Changes to these values will affect any samples they are assigned to.</Typography>
            <TableContainer
                sx={{
                    border: "1px solid",
                    borderColor: "outline-variant",
                    borderRadius: "5px",
                    mt: 3,
                    paddingBottom: "10px",
                    "& input": {
                        height: "16px",
                        fontSize: "15px",
                        padding: "10px",
                        border: "1px solid",
                        borderColor: "outline",
                        borderRadius: "5px"
                    }
                }}>
                <Table>
                    <TableHead sx={{ bgcolor: "surface-container-high" }}>
                        <TableCell width={"30%"} sx={{ padding: "7px 13px" }}>
                            Variables
                        </TableCell>
                        <TableCell width={"55%"} sx={{ padding: "7px 13px" }}>
                            Values
                        </TableCell>
                    </TableHead>
                    <TableBody>
                        {globalValues && Object.keys(globalValues).map((key, keyIndex) => ( 
                            <React.Fragment key={key}>
                                {globalValues[key].sort().map((value: any, index: any) => (
                                    <TableRow
                                        key={index}
                                    >
                                        {index === 0 && (
                                            <TableCell
                                                rowSpan={Object.entries(globalValues[key]).length}
                                                sx={{
                                                    borderBottom: "none",
                                                    verticalAlign: "top",
                                                    padding: "9px"
                                                }}>
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    value={editState[key] !== undefined ? editState[key] : key}
                                                    onChange={(e) => handleChange(key, e.target.value)}
                                                    InputProps={{
                                                        endAdornment: (
                                                            editState[key] !== undefined && (
                                                                <InputAdornment position="end">
                                                                    <IconButton onClick={() => handleSaveKeyChanges()}>
                                                                        <CheckBox color="primary" fontSize="medium" />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        ),
                                                    }}
                                                />
                                            </TableCell>
                                        )}
                                        <TableCell
                                            sx={{
                                                borderBottom: "none",
                                                display: "flex",
                                                gap: 2,
                                                whiteSpace: "nowrap",
                                                padding: "6px",
                                                margin: "2.5px 5px",
                                                width: "95%"
                                            }}>
                                            <TextField
                                                key={key + "-value-" + index}
                                                fullWidth
                                                variant="outlined"
                                                value={editState[`${key}-${index}`] !== undefined ? editState[`${key}-${index}`] : value}
                                                onChange={(e) => handleChange(`${key}-${index}`, e.target.value)}
                                                InputProps={{
                                                    endAdornment: (
                                                        editState[`${key}-${index}`] !== undefined && (
                                                            <InputAdornment position="end">
                                                                <IconButton onClick={() => handleSaveValueChanges()}>
                                                                    <CheckBox color="primary" fontSize="medium" />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    ),
                                                }}
                                            />
                                            <Typography variant="body" size="small" sx={{ alignSelf: "center" }}>
                                                Assigned to {data?.filter((d: any) => d.other_categories[key] == value)?.length} sample(s)
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                              
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box >
    )
}
export default VariablesTable