import React, { useState } from "react"
import { Box, Typography, Accordion, AccordionSummary, Button, TextField, MenuItem } from "@mui/material"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"


const Review: React.FC<any> = ({ formData, handleNext, samples, contrastOptions }) => {
 
    const mockDiffExpData = [
        {
            category: "Samples",
            parameters: samples.filter((s: any) => formData.samples.includes(s.id)).map((n: any) => {
                console.log(n.other_categories[Object.keys(n.other_categories)[0]])
                return {
                    sample_name: n.name,
                    sex: n.organism || n.sex,
                    group: n.other_categories[Object.keys(n.other_categories)[0]] 
                }
            })
        },
        {
            category: "Variables",
            parameters: [
                {
                    name: 'Variables',
                    parameters: {
                        values: Object.keys(formData.selected_group_options)?.map((o: any) => ' ' + o.split('_').join(' '))
                    },
                },
                {
                    name: 'Control values',
                    parameters: {
                        values: Object.entries(formData.selected_group_options)?.map((o: any) => ' ' + o[1][0])
                    },
                },
                {
                    name: 'Experiment values',
                    parameters: {
                        values: Object.entries(formData.selected_group_options)?.map((o: any) => ' ' + o[1].slice(1))
                    },
                },
            ]
        },
        {
            category: "Comparisons",
            parameters: formData.selected_contrast_options?.map((c: any) => {
                return {
                    "values": c.split(" - ").reverse()
                }
            })
        },
        {
            category: "Settings",
            parameters: [
                {
                    name: 'Parameters for differential expression (DE) and normalization',
                    values: [
                        {
                            name: 'Normalization method',
                            value: formData.normalization_method
                        },
                        {
                            name: 'Metric to filter counts',
                            value: formData.filter_source.split('_').join(' ')
                        },
                        {
                            name: 'Low Count Threshold',
                            value: formData.filter_threshold
                        },
                        {
                            name: 'Method to adjust P values',
                            value: formData.DE_padjust_method
                        },
                        {
                            name: 'Shrink Log2Fold Changes',
                            value: formData.lfc_shrinkage
                        },
                    ]
                },
                {
                    name: 'Parameters for ontology, and initial filtering of significant genes',
                    values: [
                        {
                            name: 'P Value Source',
                            value: formData.pvalue_option
                        },
                        {
                            name: 'P Value Cutoff',
                            value: formData.pvalue_threshold
                        },
                        {
                            name: "Fold Change Cutoff",
                            value: formData.fold_change_threshold
                        },
                        {
                            name: "Separate GO Analysis for Up vs Downregulated Genes",
                            value: formData.if_separate_regulation
                        },
                        {
                            name: 'Ontology P Value Cutoff',
                            value: formData.ontology_pvalue_cutoff
                        },
                        {
                            name: 'Ontology Method to Adjust P Values',
                            value: formData.GO_padjust_method
                        },
                    ]
                },
            ]
        },
    ]



    const [listStates, setListStates] = useState(
        new Array(mockDiffExpData.length).fill({ open: true, editMode: false })
    )

    return (
        <Box display="flex" flexDirection="column" gap="36px" marginBottom="24px" bgcolor={'surface-container-lowest'} p={3} borderRadius={'12px'}>
            <Box borderRadius="20px" border="1px solid" borderColor="outline-variant" overflow="hidden">
                <Accordion
                    expanded={listStates[0].open}
                    sx={{
                        backgroundColor: "unset",
                        boxShadow: "unset",
                        "& .MuiAccordionSummary-root, .MuiAccordionSummary-root.Mui-expanded": {
                            minHeight: "0"
                        },
                        "& .MuiAccordionSummary-root.Mui-expanded": {
                            borderBottom: "1px solid",
                            borderColor: "outline-variant"
                        }
                    }}>
                    <AccordionSummary
                        aria-controls="panel1d-content"
                        expandIcon={<ArrowDropUpIcon />}
                        id="panel1d-header"
                        onClick={() =>
                            setListStates(state =>
                                state.map((item, index) => {
                                    if (index === 0) {
                                        return { ...item, open: !item.open }
                                    }
                                    return item
                                })
                            )
                        }
                        sx={{
                            backgroundColor: "surface-container-high",
                            padding: "4px 4px 4px 16px",
                            height: "0",
                            minHeight: "0",
                            gap: "12px",
                            flexDirection: "row-reverse",
                            alignItems: "center",
                            "& .MuiAccordionSummary-content, .MuiAccordionSummary-content.Mui-expanded": {
                                alignItems: "center",
                                margin: 0
                            },
                            "& .MuiAccordionSummary-root.Mui-expanded": {}
                        }}>
                        <Typography variant="title" size="medium" width="100%">
                            {mockDiffExpData[0].category}
                        </Typography>
                        <Button
                            startIcon={<EditOutlinedIcon />}
                            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                e.stopPropagation()
                                handleNext(0)
                            }}>
                            Edit
                        </Button>
                    </AccordionSummary>
                    <Box padding="24px">
                        <Box
                            display="flex"
                            justifyContent={'space-between'}
                            alignContent={"center"}>
                            <Typography variant="title" size="medium" fontWeight={600} width={130}>
                                Sample name
                            </Typography>
                            <Typography variant="body" size="large" fontWeight={600} width={130}>
                                {samples[0].organism ? 'Organism' : 'Sex'}
                            </Typography>
                            <Typography variant="body" size="large" fontWeight={600} width={160}>
                              {mockDiffExpData[1].parameters[0].parameters.values}  
                            </Typography>
                        </Box>
                        {mockDiffExpData[0].parameters.map((param: any) => {
                            if ("sample_name" in param) {
                                return (
                                    <Box
                                        display="flex"
                                        justifyContent={'space-between'}
                                        alignContent={"center"}
                                    >
                                        <Typography variant="body" size="large" width={120}>
                                            {param.sample_name}
                                        </Typography>
                                        <Typography variant="body" size="large" width={120}>
                                            {param.sex.split('_').join(' ')}
                                        </Typography>
                                        <Typography variant="body" size="large" width={140}>
                                            {param.group}
                                        </Typography>
                                    </Box>
                                );
                            }
                            return null;
                        })}
                    </Box>
                </Accordion>
            </Box>

            <Box borderRadius="20px" border="1px solid" borderColor="outline-variant" overflow="hidden">
                <Accordion
                    expanded={listStates[1].open}
                    sx={{
                        backgroundColor: "unset",
                        boxShadow: "unset",
                        "& .MuiAccordionSummary-root, .MuiAccordionSummary-root.Mui-expanded": {
                            minHeight: "0"
                        },
                        "& .MuiAccordionSummary-root.Mui-expanded": {
                            borderBottom: "1px solid",
                            borderColor: "outline-variant"
                        }
                    }}>
                    <AccordionSummary
                        aria-controls="panel1d-content"
                        expandIcon={<ArrowDropUpIcon />}
                        id="panel1d-header"
                        onClick={() =>
                            setListStates(state =>
                                [...state, listStates[1].editMode]
                            )
                        }
                        sx={{
                            backgroundColor: "surface-container-high",
                            padding: "4px 4px 4px 16px",
                            height: "0",
                            minHeight: "0",
                            gap: "12px",
                            flexDirection: "row-reverse",
                            alignItems: "center",
                            "& .MuiAccordionSummary-content, .MuiAccordionSummary-content.Mui-expanded": {
                                alignItems: "center",
                                margin: 0
                            },
                            "& .MuiAccordionSummary-root.Mui-expanded": {}
                        }}>
                        <Typography variant="title" size="medium" width="100%">
                            {mockDiffExpData[1].category}
                        </Typography>
                        <Button
                            startIcon={<EditOutlinedIcon />}
                            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                e.stopPropagation()
                                handleNext(1)
                            }}>
                            Edit
                        </Button>
                    </AccordionSummary>
                    <Box padding="24px">
                        {mockDiffExpData[1].parameters.map((param: any) => {
                            console.log(param.parameters.values.sort((a:any,b:any) => b.length - a.length))
                            if ("parameters" in param) {
                                return (
                                    <Box
                                        display="flex"
                                    >
                                        <Typography variant="title" size="medium" width={380} mt={1} >
                                            {param.name}
                                        </Typography>
                                        <Typography variant="body" size="large" width={400} mt={1} >
                                            {param.parameters.values.sort((a:any,b:any) => a.length - b.length).map((v:any) => v.split(',').join(', ')).join("; ")  }
                                        </Typography>
                                    </Box>
                                );
                            }
                            return null;
                        })}
                    </Box>
                </Accordion>
            </Box>

            <Box borderRadius="20px" border="1px solid" borderColor="outline-variant" overflow="hidden">
                <Accordion
                    expanded={listStates[2].open}
                    sx={{
                        backgroundColor: "unset",
                        boxShadow: "unset",
                        "& .MuiAccordionSummary-root, .MuiAccordionSummary-root.Mui-expanded": {
                            minHeight: "0"
                        },
                        "& .MuiAccordionSummary-root.Mui-expanded": {
                            borderBottom: "1px solid",
                            borderColor: "outline-variant"
                        }
                    }}>
                    <AccordionSummary
                        aria-controls="panel1d-content"
                        expandIcon={<ArrowDropUpIcon />}
                        id="panel1d-header"
                        onClick={() =>
                            setListStates(state =>
                                state.map((item, index) => {
                                    if (index === 2) {
                                        return { ...item, open: !item.open }
                                    }
                                    return item
                                })
                            )
                        }
                        sx={{
                            backgroundColor: "surface-container-high",
                            padding: "4px 4px 4px 16px",
                            height: "0",
                            minHeight: "0",
                            gap: "12px",
                            flexDirection: "row-reverse",
                            alignItems: "center",
                            "& .MuiAccordionSummary-content, .MuiAccordionSummary-content.Mui-expanded": {
                                alignItems: "center",
                                margin: 0
                            },
                            "& .MuiAccordionSummary-root.Mui-expanded": {}
                        }}>
                        <Typography variant="title" size="medium" width="100%">
                            {mockDiffExpData[2].category}
                        </Typography>
                        <Button
                            startIcon={<EditOutlinedIcon />}
                            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                e.stopPropagation()
                                handleNext(2)
                            }}>
                            Edit
                        </Button>
                    </AccordionSummary>
                    <Box padding="24px">
                        <Box
                            display="flex"
                        >
                            <Typography variant="title" size="medium" width={400}>
                                Control
                            </Typography>
                            <Typography variant="title" size="medium" width={200}>
                                Experiment
                            </Typography>
                        </Box>
                        {mockDiffExpData[2].parameters.map((param: any) => {
                            if ("values" in param && typeof param.values?.[0] === 'string' && typeof param.values?.[1] === 'string') {
                               
                                return (
                                    <Box display="flex">
                                        <Typography variant="body" size="large" width={400} mt={1}  >
                                            {param.values[0].split('_').join(' and ')}
                                        </Typography>
                                        <Typography variant="body" size="large" width={200} mt={1}  >
                                            {param.values[1].split('_').join(' and ')}
                                        </Typography>
                                    </Box>
                                );
                            }
                            return null;
                        })}
                    </Box>
                </Accordion>
            </Box>

            <Box borderRadius="20px" border="1px solid" borderColor="outline-variant" overflow="hidden">
                <Accordion
                    expanded={listStates[3].open}
                    sx={{
                        backgroundColor: "unset",
                        boxShadow: "unset",
                        "& .MuiAccordionSummary-root, .MuiAccordionSummary-root.Mui-expanded": {
                            minHeight: "0"
                        },
                        "& .MuiAccordionSummary-root.Mui-expanded": {
                            borderBottom: "1px solid",
                            borderColor: "outline-variant"
                        }
                    }}>
                    <AccordionSummary
                        aria-controls="panel1d-content"
                        expandIcon={<ArrowDropUpIcon />}
                        id="panel1d-header"
                        onClick={() =>
                            setListStates(state =>
                                state.map((item, index) => {
                                    if (index === 3) {
                                        return { ...item, open: !item.open }
                                    }
                                    return item
                                })
                            )
                        }
                        sx={{
                            backgroundColor: "surface-container-high",
                            padding: "4px 4px 4px 16px",
                            height: "0",
                            minHeight: "0",
                            gap: "12px",
                            flexDirection: "row-reverse",
                            alignItems: "center",
                            "& .MuiAccordionSummary-content, .MuiAccordionSummary-content.Mui-expanded": {
                                alignItems: "center",
                                margin: 0
                            },
                            "& .MuiAccordionSummary-root.Mui-expanded": {}
                        }}>
                        <Typography variant="title" size="medium" width="100%">
                            {mockDiffExpData[3].category}
                        </Typography>
                        <Button
                            startIcon={<EditOutlinedIcon />}
                            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                e.stopPropagation()
                                handleNext(3)
                            }}>
                            Edit
                        </Button>
                    </AccordionSummary>
                    <Box px={5} pb={3}>
                        {mockDiffExpData[3].parameters.map((param: any) => {
                            return ( 
                            <div>
                                <Typography variant="title" size="large" my={2} >{param.name}</Typography>
                                {param.values.map((item: any) => {
                                    return <Box display="flex" gap={10} mb={1}>
                                        <Typography variant="title" size="medium" width={390}>
                                            {item.name}
                                        </Typography>
                                        <Typography variant="body" size="large" width={350}>
                                            {item.value}
                                        </Typography>
                                    </Box>
                                })
                                }
                            </div>
                            )
                        }
                        )}
                    </Box>
                </Accordion>
            </Box>
        </Box>
    )
}

export default Review
